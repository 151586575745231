import store from "@/core/services/store";
import introJs from "intro.js";
import ApiService from "@/core/services/api.service";
// const defaultRoutes = [
//   { name: "/", path: "/" },
//   { name: "login", path: "/login" },
//   { name: "loginNew", path: "/integration/login" },
//   { name: "register", path: "/register" },
//   { name: "registerNew", path: "/integration/register" },
//   { name: "logout", path: "/logout" },
//   { name: "forgot_password", path: "/forgot_password" },
//   { name: "password_reset", path: "/passwrod_reset" },
//   { name: "invoiceTemp", path: "/invoice_temp" },
//   { name: "maintenance", path: "/maintenance" },
//   { name: "onboardingRegister", path: "/onboarding/register" },
// ];

function checkGuidance(newPageName) {
  // console.log("newPageName", newPageName.name);
  // if (defaultRoutes.find((route) => route.name === newPageName.name)) {
  //   return;
  // }
  const getGuidanceFinishedPages = store?.getters?.getGuidanceFinishedPages;
  if (getGuidanceFinishedPages) {
    if (getGuidanceFinishedPages.length === 5) return;

    if (store.getters?.getPageLoading) {
      setTimeout(() => {
        if (store.getters.getPageLoading) {
          setTimeout(() => {
            main(newPageName.name, getGuidanceFinishedPages);
          }, 5000);
        } else main(newPageName.name, getGuidanceFinishedPages);
      }, 5000);
    } else main(newPageName.name, getGuidanceFinishedPages);
  }
}

function main(newPageName, getGuidanceFinishedPages) {
  if (
    store.getters.getProfileCompleteness < 100 &&
    !store.getters.getIsProfileCompletenessPageShown
  ) {
    //   show guide for complete profile
    introJs()
      .setOptions({
        steps: [
          {
            title: "Complete Your Profile",
            intro:
              "Begin your seamless fulfillment journey by filling in and uploading the necessary documents.",
            element: document.querySelector(".prof_complete_guide"),
          },
        ],
        highlightClass: "intro-no-border",
        tooltipClass: "tooltip_class",
        showBullets: false,
        skipLabel: "",
        doneLabel: "OK",
        hidePrev: true,
        tooltipPosition: "right",
      })
      .start();
    return;
  }
  if (getGuidanceFinishedPages.includes("integration")) {
    if (getGuidanceFinishedPages.includes("skus")) {
      if (getGuidanceFinishedPages.includes("asn")) {
        if (getGuidanceFinishedPages.includes("orders")) {
          if (newPageName !== "fulfillment.inventory") {
            introJs()
              .setOptions({
                steps: [
                  {
                    title: "Inventory Management",
                    intro:
                      '<p class="font-size-lg">Manage your inventory across all warehouses in one centralized page.</p> <br/> <img src="/img/s3.png" width="270" alt="Inventory">',
                    // "Manage your inventory across all warehouses in one centralized page.",
                    element: document.querySelector(
                      ".w-100.fulfillment-guide-icon > svg"
                    ),
                  },
                ],
                highlightClass: "intro-no-border",
                tooltipClass: "tooltip_class",
                showBullets: false,
                skipLabel: "",
                doneLabel: "OK",
                hidePrev: true,
                tooltipPosition: "right",
              })
              .onComplete(() => {
                ApiService.post("/api/v2/guide-page/add", {
                  entity: "inventory",
                });
              })
              .start();
          }
        } else {
          //   show guide for order page
          if (newPageName !== "fulfillment.orders") {
            introJs()
              .setOptions({
                steps: [
                  {
                    title: "Comprehensive Order Management",
                    intro:
                      '<p class="font-size-lg">Oversee all your orders on one easy-to-use page.</p><img src="/img/s3.png" width="270" alt="Orders">',
                    // intro: "Oversee all your orders on one easy-to-use page.",
                    element: document.querySelector(
                      ".w-100.fulfillment-guide-icon > svg"
                    ),
                  },
                ],
                highlightClass: "intro-no-border",
                tooltipClass: "tooltip_class",
                showBullets: false,
                skipLabel: "",
                doneLabel: "OK",
                hidePrev: true,
                tooltipPosition: "right",
              })
              .start();
          }
        }
      } else {
        //   show guide for asn page
        if (newPageName !== "warehouse_management.asn") {
          introJs()
            .setOptions({
              steps: [
                {
                  title: "ASN Management",
                  intro:
                    '<p class="font-size-lg">Seamlessly manage all your ASNs directly from the UI.</p> <br/> <img src="/img/s2.png" width="270" alt="ASN">',
                  // "Seamlessly manage all your ASNs directly from the UI.",
                  element: document.querySelector(
                    ".w-100.warehouse_management-guide-icon > svg"
                  ),
                },
              ],
              highlightClass: "intro-no-border",
              tooltipClass: "tooltip_class",
              showBullets: false,
              skipLabel: "",
              doneLabel: "OK",
              hidePrev: true,
              tooltipPosition: "right",
            })
            .start();
        }
      }
    } else {
      //   show guide for sku page
      if (newPageName !== "fulfillment.skus") {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Manage SKUs Efficiently",
                intro:
                  '<p class="font-size-lg">Head over to the SKU page to organize and track your stock-keeping units.</p> <br/> <img src="/img/s3.png" width="270" alt="SKUs">',
                // "Head over to the SKU page to organize and track your stock-keeping units.",
                element: document.querySelector(
                  ".w-100.fulfillment-guide-icon > svg"
                ),
              },
            ],
            highlightClass: "intro-no-border",
            tooltipClass: "tooltip_class",
            showBullets: false,
            skipLabel: "",
            doneLabel: "OK",
            hidePrev: true,
            tooltipPosition: "right",
          })
          .start();
      }
    }
  } else {
    //   show guide for integration page
    // &&
    //   store.getters.currentUser.permissions.includes(
    //     "order_management.integrationsNew"
    //   )
    if (newPageName !== "order_management.integrationsNew") {
      const element = document.querySelector(
        ".w-100.order_management-guide-icon > svg"
      );
      introJs()
        .setOptions({
          steps: [
            {
              title: "Effortless Integration",
              element,
              intro:
                '<p class="font-size-lg">Easily connect your online store to the platform in just a few steps.</p> <br/> <img src="/img/s4.png" width="270" alt="Integration">',
              // intro:
              //   "Easily connect your online store to the platform in just a few steps.",
            },
          ],
          highlightClass: "intro-no-border",
          tooltipClass: "tooltip_class",
          showBullets: false,
          skipLabel: "",
          doneLabel: "OK",
          hidePrev: true,
          tooltipPosition: "right",
        })
        .start();
    }
  }
}
export default checkGuidance;
