import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getMESSAGEPROCESSRULESTableStructure";
export const UPDATE_TABLE_DATA = "updateMESSAGEPROCESSRULESTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setMESSAGEPROCESSRULESTableStructure";
export const SET_TABLE_DATA = "setMESSAGEPROCESSRULESTableData";
export const SET_TABLE_FILTER = "setMESSAGEPROCESSRULESTableFilter";
export const SET_TABLE_PAGE = "setMESSAGEPROCESSRULESTablePage";
export const SET_TABLE_PER_PAGE = "setMESSAGEPROCESSRULESTablePerPage";
export const SET_TABLE_SORT_BY = "setMESSAGEPROCESSRULESTableSortBy";
export const SET_NOT_VALID = "setMESSAGEPROCESSRULESNotValid";
export const SET_ACTION_NOT_VALID = "setMESSAGEPROCESSRULESAcitonNotValid";
export const SET_COURIER_TYPE = "setMESSAGEPROCESSRULESType";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  notValid: true,
  actionNotValid: true,
  type: 1,
};

const getters = {
  getMESSAGEPROCESSRULESTableProperties(state) {
    return state.table_structure.properties;
  },
  getMESSAGEPROCESSRULESTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getMESSAGEPROCESSRULESTableState(state) {
    return state.table_state;
  },
  getMESSAGEPROCESSRULESTableHeaders(state) {
    return state.table_structure.columns;
  },

  getMESSAGEPROCESSRULESTableFilters(state) {
    return state.table_structure.filters;
  },

  getMESSAGEPROCESSRULESTableData(state) {
    return state.table_data.data;
  },

  getMESSAGEPROCESSRULESNotValid(state) {
    return state.notValid;
  },
  getMESSAGEPROCESSRULESAcitonNotValid(state) {
    return state.actionNotValid;
  },
  getMESSAGEPROCESSRULESType(state) {
    return state.type;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/datahub/message_process_rules")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/datahub/message_process_rules/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_NOT_VALID](state, value) {
    state.notValid = value;
  },
  [SET_ACTION_NOT_VALID](state, value) {
    state.actionNotValid = value;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PER_PAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORT_BY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_COURIER_TYPE](state, type) {
    state.type = type;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
